:root {
    --gradient: linear-gradient(90deg, #FF4D4D, #A100ED, #FF4D4D);

}

html {
    
    height:100%;
    overflow-x:hidden;
    
}

body{
    background: #FBF0FF;
    color:#1E1E1E;
}

* {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}



.gradient {
    background-image: var(--gradient);
    background-clip: text;
    background-size: 300%;
    color: transparent;
    animation: bg-animation 10s infinite;
    display:inline;

}

.gamer{
    position:absolute;
    height:887px;
    width:100%;
    object-fit:cover;
    z-index:-10;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

@keyframes bg-animation {
    0% {
        background-position: left
    }

    50% {
        background-position: right
    }

    100% {
        background-position: left
    }
}

  .arrow{
      position: relative;
      top: 66%;
      left: 50.15%;
      transform: translate(-50%,-50%);
      
  }
  .arrow span{
      display: block;
      width: 15px;
      height: 15px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg);
      margin: -4px;
      animation: animate 2s infinite;
  }
  .arrow span:nth-child(2){
      animation-delay: -0.2s;
  }
  .arrow span:nth-child(3){
      animation-delay: -0.4s;
  }


  
  @keyframes animate {
      0%{
          opacity: 0;
          transform: rotate(45deg) translate(-20px,-20px);
      }
      50%{
          opacity: 1;
      }
      100%{
          opacity: 0;
          transform: rotate(45deg) translate(20px,20px);
      }
  }


  
  




::-webkit-scrollbar {
    width: 1vw;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #aeb5b6;
    border-radius: 20px;
    border: .3vw solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #595d5e;
  }

